//IE FIX position:fixed scrolling
if (navigator.userAgent.match(/Trident\/7\./)) {
    document.addEventListener('DOMContentLoaded', function () {
        var body = document.body;

        body.addEventListener('mousewheel', function (e) {
            e.preventDefault();

            var wheelDelta = e.wheelDelta,
                currentScrollPosition = window.pageYOffset;

            window.scrollTo(0, currentScrollPosition - wheelDelta);
        });

        body.addEventListener('keydown', function (e) {
            var currentScrollPosition = window.pageYOffset;

            switch (e.which) {

                case 38: // up
                    e.preventDefault(); // prevent the default action (scroll / move caret)
                    window.scrollTo(0, currentScrollPosition - 120);
                    break;

                case 40: // down
                    e.preventDefault(); // prevent the default action (scroll / move caret)
                    window.scrollTo(0, currentScrollPosition + 120);
                    break;

                default:
                    return; // exit this handler for other keys
            }
        });
    });
}
